import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getMainTableData } from "../../Actions/MerosActions";
import { BaseUrl } from "../../BaseUrl";
import ImageZoom from "react-medium-image-zoom";
import Body from "../Body";
import { vils } from "../Modal/Forms/vils";



const Brend = ({ getMainTableData, path }) => {
  const columns = [
    {
      title: <div className="table_head">№</div>,
      key: "index",
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">Фамилия исми</div>,
      dataIndex: "fam_ism",
      key: "nomi",
      },
      {
        title: <div className="table_head">Телефон</div>,
        dataIndex: "telefon",
        key: "nomi",
      },
      {
        title: <div className="table_head">Телефон 2</div>,
        dataIndex: "telefon_2",
        key: "nomi",
      },
      {
        title: <div className="table_head">Вилоят</div>,
        dataIndex: "vil_id",
          key: "nomi",
          render: (text) => {
            if (vils && vils.find(d=> d.id == text)) {
               return vils.find(d=> d.id == text).nomi
            }else return null
        }
      },
      {
        title: <div className="table_head">Манзил</div>,
        dataIndex: "manzil",
        key: "nomi",
      },
      {
        title: <div className="table_head">Туғулган сана</div>,
        dataIndex: "birth",
        key: "nomi",
      },
      {
        title: <div className="table_head">Парол</div>,
        dataIndex: "parol",
        key: "nomi",
    },
      
    {
      title: <div className="table_head">Rasm</div>,
      dataIndex: "img",
      key: "rasm",
      render: text => {
        if (text) {
          return (
            <ImageZoom
              image={{
                src: BaseUrl + text.slice(1),
                alt: "Golden Gate Bridge",
                className: "table_img",
              }}
            />
          );
        } else return "";
      },
      width: "50px",
      align:"center"
    },
  ];
    useEffect(() => {
    getMainTableData(path);
  }, []);
  return <Body columns={columns}></Body>;
};

export default connect(null, { getMainTableData })(Brend);
