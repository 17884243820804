export const GET_LOGIN = "GET_LOGIN";
export const SET_LOADING = "SET_LOADING";
export const GET_MAXSULOTLAR = "GET_MAXSULOTLAR";
export const SET_MAIN_TABLE_DATA = "SET_MAIN_TABLE_DATA";
export const SET_ROW_ID = "SET_ROW_ID";
export const SET_MODAL = "SET_MODAL";
export const SET_VALUES = "SET_VALUES";
export const GET_RUXSATLAR = "GET_RUXSATLAR";
export const OPEN_SIDE = "OPEN_SIDE";
export const GET_ALLDATA = "GET_ALLDATA";
export const OPEN_INFO = "OPEN_INFO";
export const SET_INFO_TABLE_DATA = "SET_INFO_TABLE_DATA";
export const SET_DOCUMENT_ID = "SET_DOCUMENT_ID";
export const GET_OSTATKA = "GET_OSTATKA";
export const SET_MODAL_DATA = "SET_MODAL_DATA";
export const SET_S_MODAL = "SET_S_MODAL";
export const SET_PRINTDATA = "SET_PRINTDATA";
export const SET_CARD_DATA = "SET_CARD_DATA"