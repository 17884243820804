import React, { useState } from "react";
import { Button, message, Select } from "antd";
import { Option } from "antd/lib/mentions";
import FetchApi from "../../../api/FetchApi";
import { connect } from "react-redux";
import { setCardData } from "../../../Actions/MerosActions";
import uuid from "react-uuid";
import { useEffect } from "react";
function numberFormat(x) {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  } else return x;
}
const SotishForm = ({ clearModal, cardData, setCardData, act, allData }) => {
  const [loading, setLoading] = useState(false);
  const [summa, setSumma] = useState({
    jami: 0,
    cashback: 0,
    skidka: 0,
  });
  const [usta, setUsta] = useState(null);
  const onSearch = () => {};

  useEffect(() => {
    cardData.forEach(d => {
      setSumma({
        ...summa,
        jami: (summa.jami += d.count * d.narxi),
        cashback: (summa.cashback +=
          Math.trunc((d.count * d.narxi) / 100000) * d.bonus),
      });
    });
  }, [cardData]);

  const handleSubmit = async () => {
    if (usta) {
      setLoading(true);
      let a = 0;
      if (act == "vozvrat") {
        a = 1;
      }
      const obj = {
        id: uuid(),
        usta_id: usta,
        jami: summa.jami,
        filial_id: window.localStorage.getItem("user"),
        data: cardData,
        cashback: summa.cashback,
        holat: a,
      };

      const res = await FetchApi("/sotuv", "post", obj);
      if (res && res.status == 200) {
        message.success(
          `${act == "sotish" ? "Сотув" : "Возврат"} амалга оширилди`
        );
        clearModal();
        setUsta(null);
        setCardData([]);
      }
      setLoading(false);
    } else {
      message.warning("Устани танланг!");
    }
  };

  //   const onSkidka = v => {};

  return (
    <div>
      <div className="filial_body">
        <div>
          <span>Жами сумма:</span>
          <h2 style={{ color: "var(--main-color)", fontWeight: "bold" }}>
            {numberFormat(summa.jami)} сўм
          </h2>
        </div>
        <div>
          <span>Балл:</span>
          <h5>{numberFormat(summa.cashback)}</h5>
        </div>
      </div>
      <div style={{ paddingTop: "15px", borderTop: "1px solid #eee" }}>
        <div className="form_group">
          <Select
            showSearch
            // value={values.tur_id ? values.tur_id : undefined}
            style={{ width: "100%" }}
            placeholder="Устани танланг"
            optionFilterProp="children"
            value={usta}
            onSearch={onSearch}
            onChange={v => setUsta(v)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {allData.ustalar.map(d => (
              <Option key={d.id}>{d.fam_ism + " " + d.telefon}</Option>
            ))}
          </Select>
        </div>
      </div>

      <div className="form_submit">
        <div style={{ display: "flex" }}>
          <Button onClick={clearModal} className="btn btn-back">
            Ortga
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            loading={loading}
            onClick={handleSubmit}
            className="btn btn-submit"
          >
            {act == "sotish" ? "Сотиш" : "Возврат"}
          </Button>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    values: state.meros.values,
    cardData: state.meros.cardData,
    allData: state.meros.allData,
  };
};
export default connect(mapStateToProps, {
  setCardData,
})(SotishForm);
