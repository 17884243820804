import React, { useEffect, useState } from "react";
import { RiAddCircleFill, RiPrinterFill } from "react-icons/ri";
import { RiDeleteBinFill } from "react-icons/ri";
import { MdModeEdit } from "react-icons/md";
import { connect } from "react-redux";
import { getMainTableData, setModal, setValues } from "../Actions/MerosActions";
import { Button, message, Popconfirm } from "antd";
import FetchApi from "../api/FetchApi";
import { Route, useHistory } from "react-router";
import { Link } from "react-router-dom";
const Actions = ({
  setModal,
  rowId,
  getMainTableData,
  setValues,
  act,
  mainTableData,
}) => {
  const [loading, setLoading] = useState(false);
  const {location} = useHistory()
  const [title, setTitle] = useState("")
  useEffect(() => {
      switch (location.pathname) {
        case "/":
          setTitle("Бренд")
          break;
          case "/maxsulotturi":
            setTitle("Махсулот тури")
          break;
          case "/olchov":
            setTitle("Ўлчов бирлиги")
          break;
          case "/maxsulotlar":
            setTitle("Махсулотлар")
            break;
            case "/ustalar":
              setTitle("Усталар")
          break;
          case "/filiallar":
            setTitle("Филиаллар")
          break;
          case "/foydalanuvchi":
            setTitle("Фойдаланувчи")
          break;
          case "/sotuv":
            setTitle("Махсулотлар")
            break;
        default:
          break;
      }
  },[])
  const openEdit = () => {
    if (rowId) {
      let obj = mainTableData.find(d => d.id == rowId);
      setValues(obj);
      setModal({
        title: title,
        open: true,
        status: "edit",
      });
    } else {
      message.warning("Qatorni tanlang!");
    }
  };
 
  const handleDelete = async () => {
    if (rowId) {
      setLoading(true);
      await FetchApi(location.pathname + "/" + rowId, "DELETE");
      getMainTableData(location.pathname);
      setLoading(false);
    } else {
      message.warning("Qatorni tanlang!");
    }
  };

  const print = () => {
    setTimeout(() => {
      window.print();

      message.destroy("error");
    }, 300);
  };
  return (
    <div style={{display:"flex", }}>
     {!act && <Button
        onClick={() =>
          setModal({
            title,
            open: true,
            status: "add",
          })
        }
        type="primary"
        className="action_btn"
      >
        <RiAddCircleFill></RiAddCircleFill> &nbsp;
        Қўшиш
      </Button>}
      &nbsp; &nbsp;
      <Button
        onClick={openEdit}
        type="primary"
        className="action_btn yellow"
      >
        <MdModeEdit></MdModeEdit> &nbsp;
        Ўзгартириш
      </Button>
      &nbsp; &nbsp;
      <Popconfirm
        title="Ma'lumot o'chiriladi?"
        onConfirm={handleDelete}
        okText="Ha"
        cancelText="Yo'q"
        okButtonProps={{ loading: loading }}
      >
        <Button icon className="action_btn_red">
          <RiDeleteBinFill></RiDeleteBinFill> &nbsp; Ўчириш
        </Button>
        <Route exact path="/chiqim">
          <Link to="/print" onClick={print}>
            <Button icon className="action_btn">
              <RiPrinterFill></RiPrinterFill>
            </Button>
          </Link>
        </Route>
      </Popconfirm>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    modal: state.meros.modal,
    values: state.meros.values,
    rowId: state.meros.rowId,
    mainTableData: state.meros.mainTableData,
  };
};
export default connect(mapStateToProps, {
  setModal,
  getMainTableData,
  setValues,
})(Actions);
