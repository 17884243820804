import React from "react";
import "./Content.css";
import { Switch, Route } from "react-router-dom";
const Content = ( {routes} ) => {
  return (
    <div>
      <Switch>
        {routes.map(r=>   <Route exact path={r.path}>
          {r.comp}
        </Route>)}
        //change also modalForm
      </Switch>
    </div>
  );
};
export default Content;
