import { Select } from "antd";
import { Option } from "antd/lib/mentions";
import React from "react";
import { connect } from "react-redux";
import { Form } from "semantic-ui-react";

const UsersForm = ({ onChange, values, allData }) => {
    function onSearch(val) {
        console.log('search:', val);
      }
    return (
      <>
      <div className="usta_form usta-2-1">
      <div className="form_group">
        <Form.Field>
          <label>Фамилия исми</label>
          <input
            value={values.fio ? values.fio : ""}
            name="fio"
            onChange={onChange}
          />
        </Form.Field>
                </div>
                <div className="form_group">
            <Form.Field>
            <Select
    showSearch
    value={values.filial_id ? values.filial_id: undefined}
    style={{ width: "100%" }}
    placeholder="Филиални танланг"
    optionFilterProp="children"
    onChange={(value)=> onChange({target: {name: "filial_id", value}})}
    onSearch={onSearch}
    filterOption={(input, option) =>
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
              >
                            <Option value="0">Админ</Option>
                            {allData.filial ? allData.filial.map(v => <Option value={v.id}>{ v.nomi}</Option>): null}
  </Select>
            </Form.Field>
                </div>

            </div>


        <br></br>

            <div className="usta_form usta-2-1">
          
        
                <div className="form_group">
        <Form.Field>
          <label>Логин</label>
          <input
            value={values.login ? values.login : ""}
                      name="login"
                      type="text"
            onChange={onChange}
          />
        </Form.Field>
                </div>
                <div className="form_group">
        <Form.Field>
          <label>Парол</label>
          <input
            value={values.parol ? values.parol : ""}
                      name="parol"
                      type="text"
            onChange={onChange}
          />
        </Form.Field>
        </div>
            </div>
            </>
  );
};
const mapStateToProps = state => {
    return {
        allData: state.meros.allData
    }
}
export default connect(mapStateToProps)(UsersForm);
