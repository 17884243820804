import { DatePicker, Select } from "antd";
import { Option } from "antd/lib/mentions";
import React from "react";
import PhoneInput from "react-phone-input-2";
import { Form } from "semantic-ui-react";
import { vils } from "./vils";

const UstaForm = ({ onChange, values, title }) => {
    function onSearch(val) {
        console.log('search:', val);
      }
    return (
      <>
      <div className="usta_form usta-2-1">
      <div className="form_group">
        <Form.Field>
          <label>Фамилия исми</label>
          <input
            value={values.fam_ism ? values.fam_ism : ""}
            name="fam_ism"
            onChange={onChange}
          />
        </Form.Field>
          </div>
          <div className="form_group">
        <Form.Field>
          <label>Асосий телефон рақами</label>
          <PhoneInput
              country={"uz"}
              disableDropdown={true}
              //   disableCountryCode={true}
              countryCodeEditable={false}
              inputStyle={{
                width: "100%",
                height: "32px",
                borderRadius: "3px",
                paddingLeft: "5px",
              }}
              value={values.telefon ? values.telefon : "+998"}
              areaCodes={{
                uz: ["+998"],
              }}
              masks={{ uz: "(..) ...-..-.." }}
              prefix="+"
              onChange={phone => {
                const e = {
                  target: {
                    name: "telefon",
                    value: phone,
                  },
                };
                onChange(e);
              }}
            />
        </Form.Field>
          </div>
        
            </div>


        <br></br>

            <div className="usta_form usta-1-2">
          
          <div className="form_group">
            <Form.Field>
            <Select
    showSearch
    value={values.vil_id ? values.vil_id: undefined}
    style={{ width: "100%" }}
    placeholder="Вилоятни танланг"
    optionFilterProp="children"
    onChange={(value)=> onChange({target: {name: "vil_id", value}})}
    onSearch={onSearch}
    filterOption={(input, option) =>
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
                        >
                            {vils.map(v => <Option value={v.id}>{ v.nomi}</Option>)}
  </Select>
            </Form.Field>
                </div>
                <div className="form_group">
        <Form.Field>
          <label>Манзил</label>
          <input
            value={values.manzil ? values.manzil : ""}
                      name="manzil"
                      type="text"
            onChange={onChange}
          />
        </Form.Field>
        </div>
            </div>
            




<br></br>

            <div className="usta_form usta-3">
      <div className="form_group">
        <Form.Field>
          <label>Туғулган санаси</label>
          <div><DatePicker onChange={(date, dateString)=> onChange({target: {name:"birth", value:dateString}})} /></div>
        </Form.Field>
          </div>
          <div className="form_group">
        <Form.Field>
          <label>Қўшимча телефон рақами</label>
          <PhoneInput
              country={"uz"}
              disableDropdown={true}
              //   disableCountryCode={true}
              countryCodeEditable={false}
              inputStyle={{
                width: "100%",
                height: "32px",
                borderRadius: "3px",
                paddingLeft: "5px",
              }}
              value={values.telefon_2 ? values.telefon_2 : "+998"}
              areaCodes={{
                uz: ["+998"],
              }}
              masks={{ uz: "(..) ...-..-.." }}
              prefix="+"
              onChange={phone => {
                const e = {
                  target: {
                    name: "telefon_2",
                    value: phone,
                  },
                };
                onChange(e);
              }}
            />
        </Form.Field>
          </div>
          <div className="form_group">
        <Form.Field>
          <label>Парол</label>
          <input
            value={values.parol ? values.parol : ""}
            name="parol"
            onChange={onChange}
          />
        </Form.Field>
          </div>
            </div>
            </>
  );
};

export default UstaForm;
