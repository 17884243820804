import { Button, message } from "antd";
import React from "react";
import { useEffect } from "react";
import {
  RiAddCircleFill,
  RiArrowLeftFill,
  RiArrowRightFill,
  RiDeleteBin5Fill,
  RiIndeterminateCircleFill,
} from "react-icons/ri";
import { connect } from "react-redux";
import {
  setCardData,
  setDocumentId,
  setModal,
} from "../../Actions/MerosActions";
import "./Card.css";
function numberFormat(x) {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  } else return x;
}
const CardArea = ({
  data,
  setModal,
  modal,
  documentId,
  setCardData,
  setDocumentId,
}) => {
  useEffect(() => {
    document.querySelector(".ant-input").focus();
  }, [modal.open]);
  let a = 0;
  let cashback = 0;
  data.forEach(d => {
    a += d.count * d.narxi;
    // cashback += (d.count * d.narxi * d.bonus) / 100; // bonus
    cashback += Math.trunc((d.count * d.narxi) / 100000) * d.bonus; // ball
  });

  const handleAction = x => {
    switch (x) {
      case "+":
        const maped = data.map(d => {
          if (d.id == documentId) {
            return { ...d, count: d.count + 1 };
          } else return d;
        });

        setCardData(maped);
        break;
      case "-":
        const mapedMinus = data.map(d => {
          if (d.id == documentId) {
            if (d.count > 1) {
              return { ...d, count: d.count - 1 };
            } else return d;
          } else return d;
        });
        setCardData(mapedMinus);
        break;
      case "0":
        const mapedDelete = data.filter(d => d.id != documentId);
        setCardData(mapedDelete);
      default:
        break;
    }
  };

  const handleSotish = () => {
    if (data.length > 0) {
      setModal({
        title: "Сотиш",
        open: true,
        status: "sotish",
      });
    } else {
      message.warning("Махсулот устига 2 маротаба босинг.");
    }
  };

  const vozvrat = () => {
    if (data.length > 0) {
      setModal({
        title: "Возврат",
        open: true,
        status: "vozvrat",
      });
    } else {
      message.warning("Махсулот устига 2 маротаба босинг.");
    }
  };
  return (
    <div className="card_area">
      <div className="card_header">
        <h3>Танланган маҳсулотлар</h3>
      </div>
      <div className="card_body">
        <div className="card_item">
          <div style={{ color: "#828282" }}>Номи</div>
          <div style={{ color: "#828282" }}>Нархи</div>
        </div>

        {data.map(d => (
          <div
            className={d.id == documentId ? "card_item_active" : "card_item"}
            onClick={() => setDocumentId(d.id)}
          >
            <h4 style={{ color: "#0044C5" }}>{d.nomi}</h4>
            <div>
              <h5>
                {d.count} х {numberFormat(d.count * d.narxi)}
              </h5>
            </div>
          </div>
        ))}
      </div>
      <div className="card_footer">
        <div className="card_info">
          <div className="card_item">
            <div>
              <h4 style={{ color: "#4F4F4F", fontWeight: "500" }}>
                Жами сумма:
              </h4>
            </div>
            <div>
              <h3 style={{ color: "#0044C5", fontWeight: "500" }}>
                {numberFormat(a)} сўм
              </h3>
            </div>
          </div>
          <div className="card_item">
            <div>
              <h4 style={{ color: "#4F4F4F", fontWeight: "500" }}>Балл:</h4>
            </div>
            <div>
              <h5 style={{ color: "#0044C5", fontWeight: "500" }}>
                {numberFormat(cashback)}
              </h5>
            </div>
          </div>
        </div>
        <div className="card_actions">
          <Button onClick={() => handleAction("0")} type="primary">
            <RiDeleteBin5Fill></RiDeleteBin5Fill>
          </Button>
          <Button onClick={() => handleAction("-")} type="primary">
            <RiIndeterminateCircleFill></RiIndeterminateCircleFill>
          </Button>
          <Button onClick={() => handleAction("+")} type="primary">
            <RiAddCircleFill></RiAddCircleFill>
          </Button>
        </div>
        <div className="card_submit">
          <Button type="primary" onClick={vozvrat} danger>
            <div>
              <RiArrowLeftFill></RiArrowLeftFill>
            </div>{" "}
            &nbsp; <div>Қайтариш</div>
          </Button>
          <Button onClick={handleSotish} type="primary">
            <div>Cотиш</div> &nbsp;{" "}
            <div>
              <RiArrowRightFill></RiArrowRightFill>
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    data: state.meros.cardData,
    documentId: state.meros.documentId,
    modal: state.meros.modal,
  };
};
export default connect(mapStateToProps, {
  setDocumentId,
  setModal,
  setCardData,
})(CardArea);
