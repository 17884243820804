import React from "react";
import ModalForm from "./ModalForm";
import { connect } from "react-redux";
import { setModal, setRowId, setValues } from "../../Actions/MerosActions";
import "./Modal.css";
import { useHistory } from "react-router";
import Modal from "antd/lib/modal/Modal";
import { RiCloseCircleFill } from "react-icons/ri";
import FilialForm from "./FilialForm";
function ModalExampleModal({ setModal, modal, setValues,filial, setRowId }) {
  const clearModal = () => {
    setModal({
      open: false,
      title: null,
      status: null,
    });
    setRowId(null);
    setValues({ });
  };

  const { location } = useHistory();
  return (
    <Modal
      centered
      title={
        <div className="modal_title">
          <span style={{ textTransform: "capitalize" }}>
            {modal.title}
          </span>
          {modal.status == "edit" ? " ўзгартириш" : " қўшиш"}
        </div>
      }
      onCancel={clearModal}
      visible={modal.open}
      footer={null}
      width={location.pathname == "/sotuv" ? "1200px": (filial ? "600px": "700px")}
      closeIcon={
        <RiCloseCircleFill
          style={{
            fontSize: "25px",
            color: "#FF4B4B",
            verticalAlign: "middle",
          }}
        ></RiCloseCircleFill>
      }
    >
      <div className="modal_content">
       {filial ? <FilialForm act={modal.status} clearModal={clearModal}></FilialForm> : <ModalForm clearModal={clearModal} path={location.pathname}></ModalForm>}
      </div>
    </Modal>
  );
}
const mapStateToProps = state => {
  return {
    modal: state.meros.modal,
  };
};
export default connect(mapStateToProps, {
  setModal,
  setValues,
  setRowId,
})(ModalExampleModal);
