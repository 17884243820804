import React from "react";

const SideLink = ({ text, active, icon, layer }) => {
  return (
    <div className={active ? "link_item_active" : "link_item"}>
      <div style={icon ? { marginTop: "2px" } : { marginTop: "-4px" }}>
        {icon ? icon : <img src={layer} className="link_image" alt="layer" />}
      </div>
      &nbsp;&nbsp;&nbsp;
      <div>{text}</div>
    </div>
  );
};

export default SideLink;
