import { Menu, Dropdown } from "antd";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getAllData,
  getMainTableData,
  setModal,
  setValues,
} from "../../Actions/MerosActions";
import Body from "../Body";

const Brend = ({
  getMainTableData,
  path,
  getAllData,
  allData,
  setModal,
  setValues,
}) => {
  const duplicate = rec => {
    const a = { ...rec };
    delete a.id;
    setValues(a);
    setModal({
      title: "Махсулотлар",
      open: true,
      status: "add",
    });
  };
  const columns = [
    {
      title: <div className="table_head">№</div>,
      key: "index",
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">Махсулот номи</div>,
      dataIndex: "nomi",
      key: "nomi",
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="1" onClick={() => duplicate(record)}>
                Ko'paytirish
              </Menu.Item>
            </Menu>
          }
          trigger={["contextMenu"]}
        >
          <div style={{ width: "100%" }}>{text}</div>
        </Dropdown>
      ),
    },
    {
      title: <div className="table_head">Сотув нархи</div>,
      dataIndex: "narxi",
      key: "nomi",
    },
    {
      title: <div className="table_head">Ball (100,000)</div>,
      dataIndex: "bonus",
      key: "nomi",
    },
    {
      title: <div className="table_head">Бренд</div>,
      dataIndex: "brend_id",
      key: "nomi",
      render: text => {
        if (allData.brend && allData.brend.find(d => d.id == text)) {
          return allData.brend.find(d => d.id == text).nomi;
        } else return null;
      },
    },
    {
      title: <div className="table_head">Махсулот тури</div>,
      dataIndex: "tur_id",
      key: "nomi",
      render: text => {
        if (allData.tur && allData.tur.find(d => d.id == text)) {
          return allData.tur.find(d => d.id == text).nomi;
        } else return null;
      },
    },
    {
      title: <div className="table_head">Ўлчов бирлиги</div>,
      dataIndex: "olchov_id",
      key: "nomi",
      render: text => {
        if (allData.olchov && allData.olchov.find(d => d.id == text)) {
          return allData.olchov.find(d => d.id == text).nomi;
        } else return null;
      },
    },
  ];
  useEffect(() => {
    getAllData("/alldata");
    getMainTableData(path);
  }, []);
  return <Body columns={columns}></Body>;
};
const mapStateToProps = state => {
  return {
    allData: state.meros.allData,
  };
};
export default connect(mapStateToProps, {
  getMainTableData,
  getAllData,
  setModal,
  setValues,
})(Brend);
