import { Button, Input, Popconfirm, Select } from "antd";
import { Option } from "antd/lib/mentions";
import React from "react";
import { RiAddCircleFill, RiDeleteBinFill } from "react-icons/ri";
import { connect } from "react-redux";
import { setValues, setOpenInfo } from "../../../Actions/MerosActions";
import KirimTable from "../../Table/KirimTable";
import ModalInfo from "../ModalInfo";

const SotuvForm = ({
  setOpenInfo,
  documentId,
  documentData,
  setValues,
  allData,
}) => {
  const onChange = e => {
    setValues({
      ...documentData,
      [e.target.name]: e.target.value,
      user_id: window.localStorage.getItem("user"),
    });
  };
  const onDelete = () => {
    const a = [...documentData.data];
    setValues({
      ...documentData,
      data: a.filter(d => d.id !== documentId),
    });
  };
  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 4fr",
          gridGap: "10px",
          marginBottom:"10px"
        }}
      >
        <div style={{display:"flex", }}>
          <Button onClick={(e) => {
            e.preventDefault()
             setOpenInfo(true)
          }
           
          }  className="info_btn">
            <RiAddCircleFill></RiAddCircleFill>
          </Button>
          &nbsp; &nbsp;
          <Popconfirm
            title="Ma'lumot o'chiriladi?"
            okText="Ha"
            onConfirm={onDelete}
            cancelText="Yo'q"
          >
            <Button className="info_btn red">
              <RiDeleteBinFill></RiDeleteBinFill>
            </Button>
          </Popconfirm>
        </div>

            <Select
              showSearch
              value={documentData.usta_id}
              placeholder="Ўстани танланг"
              style={{ width: "150px", backgroundColor: "#DEF1F5" }}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={value => {
                const e = {
                  target: {
                    name: "usta_id",
                    value: value,
                  },
                };

                onChange(e);
              }}
            >
              {allData.ustalar.map(f => (
                <Option value={f.id}>{f.fam_ism}</Option>
              ))}
        </Select>
        <Select
              showSearch
              value={documentData.filial_id}
              placeholder="Филиал номи"
              style={{ width: "150px", backgroundColor: "#DEF1F5" }}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={value => {
                const e = {
                  target: {
                    name: "filial_id",
                    value: value,
                  },
                };

                onChange(e);
              }}
            >
              {allData.filial.map(f => (
                <Option value={f.id}>{f.nomi}</Option>
              ))}
            </Select>
      </div>
      <KirimTable></KirimTable>
      <ModalInfo></ModalInfo>
    </>
  );
};
const mapStateToProps = state => {
  return {
    documentId: state.meros.documentId,
    documentData: state.meros.values,
    allData: state.meros.allData,
  };
};
export default connect(mapStateToProps, {
  setOpenInfo,
  setValues,
})(SotuvForm);
