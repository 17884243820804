import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getMainTableData } from "../../Actions/MerosActions";
import Body from "../Body";



const Brend = ({ getMainTableData, path, nomi }) => {
  const columns = [
    {
      title: <div className="table_head">№</div>,
      key: "index",
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">{nomi} номи</div>,
      dataIndex: "nomi",
      key: "nomi",
    },
  ];
  useEffect(() => {
    getMainTableData(path);
  }, [path]);
  return <Body columns={columns}></Body>;
};

export default connect(null, { getMainTableData })(Brend);
