import { Button, message } from 'antd';
import React, { useState } from 'react'
import { connect } from 'react-redux'
import FetchApi from '../../../api/FetchApi';
import MainTable from '../../Table/MainTable'
import uuid from 'react-uuid'
import Excel from '../../../api/Excel';
function numberFormat(x) {
    if (x) {
        return  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    } else return x
}
const HisoblashishForm = ({data, clearModal, rowId}) => {
    const [loading, setLoading] = useState(false)
    const columns = [
        {
            title: <div className="table_head">№</div>,
            key: "index",
            render: (text, record, index) => index + 1,
            width: "50px",
            align: "center",
          },
          {
            title: <div className="table_head">Уста</div>,
            dataIndex: "fam_ism",
            key: "nomi",
        },
        {
            title: <div className="table_head">Телефон</div>,
            dataIndex: "telefon",
            key: "nomi",
        },
        {
            title: <div className="table_head">Бонус сумма</div>,
            dataIndex: "bonus",
            key: "nomi",
            render:(text)=>  numberFormat(text) + " сўм"
          },
    ]

    const handleSubmit = async () => {
        if (rowId) {

            setLoading(true)
            const obj = {
                id: uuid(),
                usta_id: rowId,
                bonus: data.find(d => d.id == rowId).bonus,
            }

        const res = await FetchApi("/hisoblashish", "POST", obj)
        if (res && res.status == 200) {
            message.success("Амалга оширилди")
            clearModal()
        }

        setLoading(false)
        } else {
            message.warning("Устани танланг")
        }
       
    }
    return (
        <div>
            <MainTable columns={columns} data={data}>
            </MainTable>
            <div style={{display:"flex", justifyContent:"center", paddingTop:"15px"}}>
            <Button onClick={clearModal} className="btn btn-back">
                    Ortga
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                    loading={loading}
                    onClick={handleSubmit}
                    className="btn btn-submit"
                >
                    Хисоблашиш
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button onClick={()=> Excel(data)} className="btn btn-success">
                    Excelga олиш
                </Button>
            </div>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        data: state.meros.infoTableData,
        rowId: state.meros.rowId
    }
}
export default connect(mapStateToProps)(HisoblashishForm)

