import React from "react";
import { InputNumber, Table } from "antd";
import "./Table.css";
import { connect } from "react-redux";
import { setDocumentId, setValues } from "../../Actions/MerosActions";
import Bonus from "../../api/Bonus";
function numberFormat(x) {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  } else return x;
}

const KirimTable = ({ values, documentId, setDocumentId, setValues }) => {
  const handleChange = (props, value) => {
    const a = [...values.data];
    console.log(a);
    const changedData = a.map(d => {
      if (d.id === documentId) {
        return { ...d, [props]: value };
      } else {
        return d;
      }
    });

    let cashback = 0;
    let j = 0;
    changedData.forEach(d => {
      j += d.count * d.narxi;
      cashback += (d.count * d.narxi * d.bonus) / 100;
    });

    setValues({
      ...values,
      data: changedData,
      cashback: cashback,
      jami: j,
    });
  };

  const columns = [
    {
      title: <div className="table_head">№</div>,
      key: "index",
      render: (text, record, index) => index + 1,
      width: "30px",
      align: "center",
    },
    {
      title: <div className="table_head">Товар номи</div>,
      dataIndex: "nomi",
      key: "nomi",
    },
    {
      title: <div className="table_head">Бренд</div>,
      dataIndex: "brend_nomi",
      key: "rasm",
    },
    {
      title: <div className="table_head">Товар тури</div>,
      dataIndex: "max_turi",
      key: "rasm",
      width: "12%",
    },
    {
      title: <div className="table_head">Нархи</div>,
      dataIndex: "narxi",
      key: "rulon",
    },
    {
      title: <div className="table_head">Сони</div>,
      dataIndex: "count",
      key: "soni",
      render: text => {
        return (
          <InputNumber
            min={1}
            max={100}
            value={text ? text : ""}
            onChange={v => handleChange("count", v)}
          />
        );
      },
    },
    {
      title: <div className="table_head">Суммаси</div>,
      dataIndex: "rulon",
      key: "jami",
      render: (text, record) => {
        return numberFormat(record.narxi * record.count) + " сўм";
      },
    },
    {
      title: <div className="table_head">Балл</div>,
      dataIndex: "bonus",
      key: "turi",
      render: text => {
        return (
          <InputNumber
            min={1}
            max={100}
            value={text ? text : ""}
            onChange={v => handleChange("bonus", v)}
          />
        );
      },
    },
    {
      title: <div className="table_head">Жами балл</div>,
      dataIndex: "bonus_summa",
      key: "jami",
      render: (text, record) => {
        return numberFormat(Bonus(record.narxi, record.count, record.bonus));
      },
    },
  ];

  const onClickRow = record => {
    return {
      onClick: () => {
        setDocumentId(record.id);
      },
    };
  };

  const setRowClassName = record => {
    return record.id === documentId ? "clickRowStyl" : "";
  };

  return (
    <>
      <Table
        size="small"
        // rowClassName="table_row"
        columns={columns}
        dataSource={values.data}
        onRow={onClickRow}
        rowClassName={setRowClassName}
      />
    </>
  );
};
const mapStateToProps = state => {
  return {
    values: state.meros.values,
    documentId: state.meros.documentId,
  };
};
export default connect(mapStateToProps, {
  setDocumentId,
  setValues,
})(KirimTable);
