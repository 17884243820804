import React from "react";
import {
  RiMenuFoldFill,
  RiMenuUnfoldFill,
  RiSearch2Line,
} from "react-icons/ri";
import { connect } from "react-redux";
import { openSide } from "../Actions/MerosActions";
import { SearchTableInput } from "ant-table-extensions";

const SearchCom = ({
  side,
  openSide,
  hide,
  dataSource,
  columns,
  setS,
  costumData,
}) => {
  return (
    <div style={{ display: "flex", alignItems: "center", justifySelf:"flex-start", marginLeft: "-32px" }}>
      {!hide && (
        <div className="open_side" onClick={() => openSide(!side)}>
          {side ? (
            <RiMenuFoldFill
              style={{ fontSize: "25px", color: "var(--main-color)" }}
            ></RiMenuFoldFill>
          ) : (
            <RiMenuUnfoldFill
              style={{ fontSize: "25px", color: "var(--main-color)" }}
            ></RiMenuUnfoldFill>
          )}
        </div>
      )}
      &nbsp;
      <div
        style={{
          display: "flex",
          alignItems: "center",
          
          paddingLeft: "15px",
        }}
      >
        <SearchTableInput
          columns={columns}
          dataSource={costumData ? costumData : dataSource} // 🔴 Original dataSource
          setDataSource={setS} // 🔴 Newly created setSearchDataSource from useState hook
          inputProps={{
            placeholder: "Qidiruv...",
            prefix: <RiSearch2Line></RiSearch2Line>,
          }}
        />
      </div>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    side: state.meros.openSide,
    dataSource: state.meros.mainTableData,
  };
};
export default connect(mapStateToProps, { openSide })(SearchCom);
