import { Select } from "antd";
import { Option } from "antd/lib/mentions";
import React from "react";
import { connect } from "react-redux";
import { Form } from "semantic-ui-react";

const MaxsulotlarForm = ({ onChange, values, title, alldata }) => {
  function onSearch(val) {
    console.log("search:", val);
  }
  return (
    <>
      <div className="maxsulotlar_form">
        <div className="form_group">
          <Form.Field>
            <label>{title} номи</label>
            <input
              value={values.nomi ? values.nomi : ""}
              name="nomi"
              onChange={onChange}
            />
          </Form.Field>
        </div>
        <div className="form_group">
          <Form.Field>
            <label>Сотув нархи</label>
            <input
              value={values.narxi ? values.narxi : ""}
              name="narxi"
              type="number"
              onChange={onChange}
            />
          </Form.Field>
        </div>
        <div className="form_group">
          <Form.Field>
            <label>Ball (100,000)</label>
            <input
              value={values.bonus ? values.bonus : ""}
              name="bonus"
              type="number"
              onChange={onChange}
            />
          </Form.Field>
        </div>
      </div>

      <br></br>

      <div className="maxsulotlar_form">
        <div className="form_group">
          <Form.Field>
            <Select
              showSearch
              value={values.brend_id ? values.brend_id : undefined}
              style={{ width: "100%" }}
              placeholder="Брендни танланг"
              optionFilterProp="children"
              onChange={value =>
                onChange({ target: { name: "brend_id", value } })
              }
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {alldata.brend.map(d => (
                <Option key={d.id}>{d.nomi}</Option>
              ))}
            </Select>
          </Form.Field>
        </div>
        <div className="form_group">
          <Form.Field>
            <Select
              showSearch
              value={values.tur_id ? values.tur_id : undefined}
              style={{ width: "100%" }}
              placeholder="Махсулотлар турини танланг"
              optionFilterProp="children"
              onChange={value =>
                onChange({ target: { name: "tur_id", value } })
              }
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {alldata.tur.map(d => (
                <Option key={d.id}>{d.nomi}</Option>
              ))}
            </Select>
          </Form.Field>
        </div>
        <div className="form_group">
          <Form.Field>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Ўлчов ьирлиги"
              value={values.olchov_id ? values.olchov_id : undefined}
              optionFilterProp="children"
              onChange={value =>
                onChange({ target: { name: "olchov_id", value } })
              }
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {alldata.olchov.map(d => (
                <Option key={d.id}>{d.nomi}</Option>
              ))}
            </Select>
          </Form.Field>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = state => {
  return {
    alldata: state.meros.allData,
  };
};
export default connect(mapStateToProps, {})(MaxsulotlarForm);
