import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getAllData, getMainTableData } from "../../Actions/MerosActions";
import Body from "../Body";
function numberFormat(x) {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  } else return x;
}

const Sotuv = ({ getMainTableData, path, allData, getAllData }) => {
  const columns = [
    {
      title: <div className="table_head">№</div>,
      key: "index",
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">Филиал номи</div>,
      dataIndex: "filial_nomi",
      key: "nomi",
    },
    {
      title: <div className="table_head">Вақти</div>,
      dataIndex: "time_create",
      key: "nomi",
    },
    {
      title: <div className="table_head">Жами товар сумма</div>,
      dataIndex: "jami",
      key: "nomi",
      render: text => {
        return numberFormat(text) + " сўм";
      },
      align: "center",
    },

    {
      title: <div className="table_head">Жами балл</div>,
      dataIndex: "cashback",
      key: "nomi",
      render: text => {
        return numberFormat(text) || 0 + " та";
      },
      align: "center",
    },
    {
      title: <div className="table_head">Қайси устага</div>,
      dataIndex: "usta_nomi",
      key: "nomi",
    },

    {
      title: <div className="table_head">Холати</div>,
      dataIndex: "holat",
      key: "nomi",
      render: text => (text == 0 ? "Савдо" : "Возврат"),
    },
  ];
  useEffect(() => {
    getAllData("/secdata");
    getMainTableData(path);
  }, []);
  return <Body act={true} columns={columns}></Body>;
};
const mapStateToProps = state => {
  return {
    allData: state.meros.allData,
  };
};
export default connect(mapStateToProps, { getMainTableData, getAllData })(
  Sotuv
);
