import React from "react";
import { Table } from "ant-table-extensions";
import "./Table.css";
import { connect } from "react-redux";
import {
  setModal,
  setRowId,
  setValues,
} from "../../Actions/MerosActions";

const MainTable = ({
  columns,
  data,
  costumData,
  loading,
  setRowId,
  rowS,
  rowId,
  dbclick,
  pag,
  setValues,
  setModal,
}) => {
  const onClickRow = record => {
    return {
      onClick: () => {
        setRowId(record.id);
      },
      onDoubleClick: () => {
        if (dbclick) {
          setValues(record);
          setModal({
            open: true,
            title: "Махсулот",
            status: "soni"
          });
        }
      },
    };
  };
  const setRowClassName = record => {
    return record.id === rowId ? "clickRowStyl" : "";
  };
  return (
    <>
      <div className="main_table">
        <Table
          size="small"
          bordered
          rowClassName="table_row"
          columns={columns}
          pagination={pag ? false : true}
          dataSource={costumData ? costumData : data}
          onRow={onClickRow}
          searchableProps={{ fuzzySearch: true }}
          rowSelection={rowS ? rowS : null}
          loading={loading}
          rowClassName={setRowClassName}
        />
      </div>
    </>
  );
};
const mapStateToProps = state => {
  return {
    loading: state.meros.loading,
    rowId: state.meros.rowId,
  };
};
export default connect(mapStateToProps, {
  setRowId,
  setModal,
  setValues,
})(MainTable);
