import {
  GET_LOGIN,
  GET_RUXSATLAR,
  OPEN_SIDE,
  SET_LOADING,
  SET_MAIN_TABLE_DATA,
  SET_MODAL,
  SET_ROW_ID,
  GET_ALLDATA,
  SET_VALUES,
  OPEN_INFO,
  SET_INFO_TABLE_DATA,
  SET_DOCUMENT_ID,
  GET_OSTATKA,
  SET_S_MODAL,
  SET_PRINTDATA,
  SET_MODAL_DATA,
  SET_CARD_DATA,
} from "../Actions/MerosTypes";

const initialState = {
  auth: false,
  loading: false,
  mainTableData: [],
  infoTableData: [],
  rowId: null,
  modal: {
    open: false,
    title: "Maxsulot",
  },
  values: { },
  ruxsatlar: [],
  openSide: null,
  cardData: [],
  allData: {
    brend: [],
    tur: [],
    olchov: [],
    filial: [],
    ustalar: []
  },
  openInfo: false,
  documentId: null,
  ostatka: [],
  sModal: {
    open: false,
    path: null,
    title: null,
  },
  printData: [],
};
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LOGIN:
      return {
        ...state,
        auth: !state.auth,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_OSTATKA:
      return {
        ...state,
        ostatka: action.payload,
      };
    case OPEN_SIDE:
      return {
        ...state,
        openSide: action.payload,
      };
    case GET_ALLDATA:
      return {
        ...state,
        allData: action.payload,
      };
      case SET_CARD_DATA:
        return {
          ...state,
          cardData: action.payload,
        };
    case SET_MODAL:
      return {
        ...state,
        modal: action.payload,
      };
    case SET_S_MODAL:
      return {
        ...state,
        sModal: action.payload,
      };
    case SET_ROW_ID:
      return {
        ...state,
        rowId: action.payload,
      };
      case SET_MODAL_DATA:
        return {
          ...state,
          values: {
            ...state.values,
            data: action.payload
          },
        };
    case SET_DOCUMENT_ID:
      return {
        ...state,
        documentId: action.payload,
      };
    case SET_PRINTDATA:
      return {
        ...state,
        printData: action.payload,
      };
    case SET_MAIN_TABLE_DATA:
      return {
        ...state,
        mainTableData: action.payload,
      };
    case SET_VALUES:
      return {
        ...state,
        values: action.payload,
      };
    case GET_RUXSATLAR:
      return {
        ...state,
        ruxsatlar: action.payload,
      };
    case OPEN_INFO:
      return {
        ...state,
        openInfo: action.payload,
      };
    case SET_INFO_TABLE_DATA:
      return {
        ...state,
        infoTableData: action.payload,
      };
    default:
      return state;
  }
};
