import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAllData, getMainTableData } from "../../Actions/MerosActions";
import CardArea from "../Card/CardArea";
import Header from "../Header";
import MainTable from "../Table/MainTable";
import ModalExampleModal from "../Modal/Modal";
function numberFormat(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
const FilialSavdo = ({ getMainTableData, data, getAllData }) => {
  useEffect(() => {
    getMainTableData("/filialmaxs");
    getAllData("/secdata");
  }, []);
  const [searchDataSource, setSearchDataSource] = useState([]);

  useEffect(() => {
    setSearchDataSource(data);
  }, [data]);
  const columns = [
    {
      title: <div className="table_head">№</div>,
      key: "index",
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">Номи / Артикул</div>,
      dataIndex: "nomi",
      key: "nomi",
    },
    {
      title: <div className="table_head">Сотув нархи</div>,
      dataIndex: "narxi",
      key: "nomi",
      render: text => numberFormat(text) + " сўм",
      align: "center",
    },
    {
      title: <div className="table_head">Бall (100,000)</div>,
      dataIndex: "bonus",
      key: "nomi",
    },
    {
      title: <div className="table_head">Бренд</div>,
      dataIndex: "brend_nomi",
      key: "nomi",
    },
    {
      title: <div className="table_head">Махсулот тури</div>,
      dataIndex: "max_turi",
      key: "nomi",
    },
    {
      title: <div className="table_head">Ўлчов бирлиги</div>,
      dataIndex: "olchov_nomi",
      key: "nomi",
    },
  ];
  return (
    <div>
      <Header
        hisob={true}
        columns={columns}
        setSearchDataSource={setSearchDataSource}
      ></Header>
      <div className="savdo_area">
        <MainTable
          columns={columns}
          data={searchDataSource}
          dbclick={true}
        ></MainTable>
        <CardArea></CardArea>
      </div>
      <ModalExampleModal filial={true}></ModalExampleModal>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    data: state.meros.mainTableData,
  };
};
export default connect(mapStateToProps, { getMainTableData, getAllData })(
  FilialSavdo
);
