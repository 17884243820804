import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Header from "../Header";
import MainTable from "../Table/MainTable";
import ModalExampleModal from "../Modal/Modal";
import { getMainTableData, openSide } from "../../Actions/MerosActions";
import { Button, message, Popconfirm, Select } from "antd";
import { Option } from "antd/lib/mentions";
import moment from "moment";
import FetchApi from "../../api/FetchApi";
import uuid from "react-uuid";
import Excel from "../../api/Excel";

function numberFormat(x) {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  } else return x;
}
const Xisobot = ({ data, getMainTableData, allData, rowId }) => {
  const [searchDataSource, setSearchDataSource] = useState([]);
  const [totalBonus, setTotalBonus] = useState(0);
  const [loading, setLoading] = useState(false);
  const [usta, setUsta] = useState("0");
  const [btn, setBtn] = useState(0);
  useEffect(() => {
    if (data.length > 0) {
      let a = 0;
      data.forEach(d => {
        if (d.hasOwnProperty("kirimChiqim")) {
          if (d.kirimChiqim == "0") {
            a += Number(d.bonus);
          } else {
            a -= Number(d.bonus);
          }
        } else {
          a += Number(d.bonus);
        }
      });
      setTotalBonus(a);
    }
    setSearchDataSource(data);
  }, [data]);
  useEffect(() => {
    if (usta == "0") {
      getMainTableData("/bonuslar");
    } else {
      getMainTableData(`/ustabonus/${usta}`);
    }
  }, [usta]);
  const handleClick = x => {
    setBtn(x);
  };
  const onSearch = () => {};

  const column1 = [
    {
      title: <div className="table_head">№</div>,
      key: "index",
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">Уста</div>,
      dataIndex: "fam_ism",
      key: "index",
      align: "center",
    },
    {
      title: <div className="table_head">Телефон рақам</div>,
      dataIndex: "telefon",
      key: "index",
      align: "center",
    },
    {
      title: <div className="table_head">Жами балл</div>,
      dataIndex: "bonus",
      key: "index",
      render: text => numberFormat(text) + " та",
      align: "center",
    },
  ];

  const columns2 = [
    {
      title: <div className="table_head">№</div>,
      key: "index",
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">Сана</div>,
      dataIndex: "time_create",
      key: "index",
      align: "center",
      render: text => moment(text).format("HH:mm DD/MM/YYYY"),
    },
    {
      title: <div className="table_head">Жами балл</div>,
      dataIndex: "bonus",
      key: "index",
      align: "center",
      render: text => numberFormat(text) + " та",
    },
    {
      title: <div className="table_head">Холати</div>,
      dataIndex: "kirimChiqim",
      key: "index",
      render: text => {
        let a = "";
        switch (text) {
          case 0:
            a = "савдо";
            break;
          case 1:
            a = "пул берилди";
            break;
          case 2:
            a = "возврат";
            break;
          default:
            break;
        }

        return a;
      },
    },
  ];

  const hisoblashish = async () => {
    if (rowId) {
      setLoading(true);
      const obj = {
        id: uuid(),
        usta_id: rowId,
        bonus: data.find(d => d.id == rowId).bonus,
      };

      const res = await FetchApi("/hisoblashish", "POST", obj);
      if (res && res.status == 200) {
        message.success("Амалга оширилди");
      }

      setLoading(false);
    } else {
      message.warning("Устани танланг");
    }
  };
  return (
    <>
      <Header
        columns={usta == 0 ? column1 : columns2}
        setSearchDataSource={setSearchDataSource}
      ></Header>
      <div className="Body" onClick={() => openSide(false)}>
        <div className="hisobot_btns">
          <div
            onClick={() => handleClick(0)}
            className={
              btn == 0 ? "hisobot_btn" : "hisobot_btn hisobot_btn_active"
            }
          >
            {btn == 0 && <div className="dot"></div>}Бонус ҳисоботи
          </div>
          <div
            onClick={() => handleClick(1)}
            className={
              btn == 1 ? "hisobot_btn" : "hisobot_btn hisobot_btn_active"
            }
          >
            {btn == 1 && <div className="dot"></div>}Туғулган кун ҳисоботи
          </div>
          <div
            onClick={() => handleClick(2)}
            className={
              btn == 2 ? "hisobot_btn" : "hisobot_btn hisobot_btn_active"
            }
          >
            {btn == 2 && <div className="dot"></div>}Савдо ҳисоботи
          </div>
        </div>
        <div className="hisobot_area">
          <div className="hisobot_actions">
            <Popconfirm
              title="Ishonchingiz komilmi?"
              onConfirm={hisoblashish}
              okText="Ha"
              cancelText="Yo'q"
            >
              <Button loading={loading} type="primary">
                Ҳисоблашиш
              </Button>
            </Popconfirm>
            <Button onClick={() => Excel(data)} className="green-btn">
              Excel га олиш
            </Button>
            <div>
              <Select
                showSearch
                style={{ width: "300px" }}
                placeholder="Устани танланг"
                optionFilterProp="children"
                onSearch={onSearch}
                value={usta}
                onChange={v => setUsta(v)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="0">Барча усталар</Option>
                {allData.ustalar &&
                  allData.ustalar.map(d => (
                    <Option value={d.id}>{d.fam_ism}</Option>
                  ))}
              </Select>
            </div>

            <div>
              <h3 style={{ color: "var(--main-color)" }}>
                Жами балл: {numberFormat(totalBonus) || 0} та
              </h3>
            </div>
          </div>
          <MainTable
            size="small"
            pag={true}
            columns={usta == 0 ? column1 : columns2}
            data={searchDataSource}
          ></MainTable>
        </div>
      </div>
      <ModalExampleModal></ModalExampleModal>
    </>
  );
};
const mapStateToProps = state => {
  return {
    data: state.meros.mainTableData,
    allData: state.meros.allData,
    rowId: state.meros.rowId,
  };
};
export default connect(mapStateToProps, { openSide, getMainTableData })(
  Xisobot
);
