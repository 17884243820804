import React, { useState } from "react";
import { RiPriceTag3Fill, RiVipCrownFill } from "react-icons/ri";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Menu, Segment, Sidebar } from "semantic-ui-react";
import Content from "./Content";
import "./Sidebar.css";
import { openSide, setRowId } from "../Actions/MerosActions";
import SideLink from "./SideLink";
import { useMediaQuery } from "react-responsive";
import brand from "../icons/brand.svg";
const SidebarExampleVisible = ({ loading, openSide, side, setRowId, routes }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [rout, setRout] = useState(false);
  let { location } = useHistory();
  const linkClick = () => {
    setRout(!rout);
    setRowId(null);
    if (isMobile) {
      openSide(false);
    }
  };

  return (
    <Sidebar.Pushable as={Segment}>
      <Sidebar
        as={Menu}
        animation={isMobile ? "push" : "overlay"}
        icon="labeled"
        vertical
        visible={side ? side : !isMobile}
        className="CostumSidebar"
      >
        <div className="brand">
          <img
            src={brand}
            alt="meros kosiblarga homashyo yetkizib berish brendi"
          />
        </div>
        <div className="links_block">
         
         {routes.map(r=>  <Link to={r.path} onClick={linkClick}>
            <SideLink
              text={r.title}
              icon={r.icon}
              active={location.pathname === r.path}
            ></SideLink>
          </Link>)}
        </div>
      
      </Sidebar>

      <Sidebar.Pusher>
        <Segment basic className="content" loading={loading}>
          <Content routes={routes}></Content>
        </Segment>
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
};
const mapStateToProps = state => {
  return {
    loading: state.meros.loading,
    side: state.meros.openSide,
  };
};
export default connect(mapStateToProps, { openSide, setRowId })(
  SidebarExampleVisible
);
