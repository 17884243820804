import React, { useState } from "react";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import {
  getAllData,
  getMainTableData,
  setModal,
  setValues,
} from "../../Actions/MerosActions";
import FetchApi from "../../api/FetchApi";
import BrendForm from "./Forms/BrendForm";
import SotuvForm from "./Forms/SotuvForm";
import MaxsulotlarForm from "./Forms/MaxsulotlarForm";
import UsersForm from "./Forms/UsersForm";
import UstaForm from "./Forms/UstaForm";


const ModalForm = ({
  values,
  setValues,
  getMainTableData,
  clearModal,
  path,
}) => {
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(false);
  const onChange = e => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = () => {
        RequestJson(path, values, getMainTableData);
  };


  const RequestJson = async (url, data, refreshCallback) => {
    setLoading(true);
      const response = await FetchApi(url, "POST", data);
      if (response && response.status === 200) {
        refreshCallback(url);
        clearModal();
      }
  

    setLoading(false);
    setError(false);
  };


  let comp = "";
  switch (path) {

    case "/":
      comp = <BrendForm onChange={onChange} title="Бренд" values={values}></BrendForm>;
      break;
      case "/maxsulotturi":
        comp = <BrendForm onChange={onChange} title="Махсулот" values={values}></BrendForm>;
      break;
      case "/olchov":
        comp = <BrendForm onChange={onChange} title="Ўлчов" values={values}></BrendForm>;
      break;
      case "/filiallar":
        comp = <BrendForm onChange={onChange} title="Филиал" values={values}></BrendForm>;
      break;
      case "/maxsulotlar":
        comp = <MaxsulotlarForm onChange={onChange} title="Махсулотлар" values={values}></MaxsulotlarForm>;
      break;
      case "/ustalar":
        comp = <UstaForm onChange={onChange} title="Усталар" values={values}></UstaForm>;
      break;
      case "/foydalanuvchi":
        comp = <UsersForm onChange={onChange} title="Фойдаланувчи" values={values}></UsersForm>;
      break;
      case "/sotuv":
        comp = <SotuvForm onChange={onChange} title="Махсулотлар" values={values}></SotuvForm>;
        break;
    default:
      break;
  }

  return (
    <>
      <form onSubmit={(e) => {
        e.preventDefault()
        handleSubmit()
      }}>
        {comp}
      </form>
      <div className="form_submit">
        <div style={{display:"flex"}}>
          <Button onClick={clearModal} className="btn btn-back">
            Ortga
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Button

            loading={loading}
            onClick={handleSubmit}
            className="btn btn-submit"
          >
            Saqlash
          </Button>
        </div>
        </div>
     
    </>
  );
};
const mapStateToProps = state => {
  return {
    values: state.meros.values,
    modal: state.meros.modal,
    rowId: state.meros.rowId,
  };
};
export default connect(mapStateToProps, {
  setValues,
  getMainTableData,
  setModal,
  
  getAllData,
})(ModalForm);
