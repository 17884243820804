import React from "react";
import { Table } from "antd";
import "./Table.css";

const NestedTable = ({ columns, data }) => {
  return (
    <>
      <Table
        size="small"
        rowClassName="table_row"
        columns={columns}
        dataSource={data}
        expandable={{
          defaultExpandAllRows: true,
          childrenColumnName: "children",
        }}
      />
    </>
  );
};
export default NestedTable;
