import React from 'react'
import avatar from '../icons/avatar.svg'
import SearchCom from "./SearchCom";
import { GiExitDoor } from "react-icons/gi";
import { connect } from 'react-redux';
import brand from "../icons/brand.svg";
import { RiHandCoinFill } from 'react-icons/ri';
import { getInfoTableData, setModal } from '../Actions/MerosActions';
const Header = ({ columns, setSearchDataSource , hisob,setModal,getInfoTableData}) => {
    const leave = () => {
        window.localStorage.removeItem("user");
        window.location.href="/"
      
    };
    return (
        <div className={window.localStorage.getItem("user") !=0 ? "savdo_header": "header"}>
          {window.localStorage.getItem("user") !=0 && <div className="brand">
                <img
                    src={brand}
                    alt="meros kosiblarga homashyo yetkizib berish brendi"
                />
             </div>}
            
      
        <SearchCom  columns={columns} setS={setSearchDataSource}></SearchCom>
        <div style={{display:"flex", alignItems:"center"}}>
      
          <div className="avatar">
            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-end"}}>
              <h4>{ window.localStorage.getItem("fio")}</h4>
              <span>+{window.localStorage.getItem("tel")}</span>
            </div>
            <img style={{display:"block"}} src={avatar}></img>
          </div>
         {hisob && <div
          style={{
            fontSize: "24px",
            color: "var(--main-color)",
            cursor: "pointer",
          }}
            onClick={() => {
              getInfoTableData("/bonuslar")
              setModal({
                title: "Хисоб китоб",
                open: true,
                status: "hisoblashish"
            })
          }}
        >
          <RiHandCoinFill></RiHandCoinFill>
          </div>}
          &nbsp;
          &nbsp;
        <div
          style={{
            fontSize: "24px",
            color: "var(--main-color)",
            cursor: "pointer",
          }}
          onClick={leave}
        >
          <GiExitDoor></GiExitDoor>
          </div>
        </div>
      </div>
    )
}

export default connect(null, {setModal,getInfoTableData})(Header)
