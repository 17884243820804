import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SearchCom from "../../SearchCom";
import MainTable from "../../Table/MainTable";

const columns = [
  {
    title: <div className="table_head">№</div>,
    key: "index",
    render: (text, record, index) => index + 1,
    width: "50px",
    align: "center",
  },
  {
    title: <div className="table_head">Nomi</div>,
    dataIndex: "nomi",
    key: "nomi",
  },
];

const KirimInfo = ({ maxsulotlar }) => {
  const [searchDataSource, setSearchDataSource] = useState([]);
  useEffect(() => {
    setSearchDataSource(maxsulotlar);
  }, [maxsulotlar]);
  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <SearchCom
          hide={true}
          columns={columns}
          setS={setSearchDataSource}
          costumData={maxsulotlar}
        ></SearchCom>
      </div>
      <MainTable columns={columns} costumData={searchDataSource}></MainTable>
    </>
  );
};
const mapStateToProps = state => {
  return {
    maxsulotlar: state.meros.allData.maxsulotlar,
  };
};
export default connect(mapStateToProps, {})(KirimInfo);
