import { message } from "antd";

export default data => {
  let a = Object.keys(data).length > 0;
  const formData = new FormData();

  for (const key in data) {
    if (data[key] !== "") {
      formData.append(key, data[key]);
    } else {
      a = false;
    }
  }

  if (a) {
    return formData;
  } else {
    message.warning("Bo'sh joylarni to'ldiring");
    return false;
  }
};
