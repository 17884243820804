import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SearchCom from "../../SearchCom";
import _ from "underscore";
import NestedTable from "../../Table/NestedTable";
import uuid from "react-uuid";
import { setModalData, setOpenInfo } from "../../../Actions/MerosActions";
import { useHistory } from "react-router";
function numberWithSpaces(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const columns = [
  {
    title: <div className="table_head">Nomi</div>,
    dataIndex: "nomi",
    key: "name",
  },
  {
    title: <div className="table_head">Rulon soni</div>,
    dataIndex: "rulon_soni",
    key: "age",
    width: "22%",
  },
  {
    title: <div className="table_head">Jami</div>,
    dataIndex: "jami",
    width: "30%",
    key: "address",
  },
];

const ChiqimInfo = ({
  maxsulotlar,
  ostatka,
  rulon,
  rowId,
  setOpenInfo,
  setModalData,
}) => {
  const [searchDataSource, setSearchDataSource] = useState([]);
  const [data, setData] = useState([]);
  const { location } = useHistory();
  useEffect(() => {
    const groups = _.groupBy(ostatka, function (value) {
      return "maxsulot" + value.maxsulot_id;
    });

    if (maxsulotlar.length > 0 && rulon.length > 0) {
      const ostdata = [];
      Object.keys(groups).forEach(key => {
        const id = key.slice(8);
        let rulon_soni = 0;
        let jami_metr = 0;
        let children = [];
        let child1 = {
          key: uuid(),
          nomi: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="circle c_g"></div>Yashil
            </div>
          ),
          rulon_soni: 0,
          jami: 0,
          children: [],
        };
        let child2 = {
          key: uuid(),
          nomi: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="circle c_y"></div>Sariq
            </div>
          ),
          rulon_soni: 0,
          jami: 0,
          children: [],
        };
        let child3 = {
          key: uuid(),
          nomi: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="circle c_r"></div>Qizil
            </div>
          ),
          rulon_soni: 0,
          jami: 0,
          children: [],
        };

        groups[key].forEach(gr => {
          const metr = rulon.find(r => r.id == gr.rulon).metr;
          rulon_soni += gr.soni * 1;
          jami_metr += gr.soni * 1 * metr * 1;
          const obj = {};
          switch (gr.turi) {
            case "g":
              child1.rulon_soni += gr.soni * 1;
              child1.jami += gr.soni * 1 * metr * 1;
              obj.key = gr.id;
              obj.nomi = (
                <div onClick={() => handleClick(gr)}>
                  {rulon.find(r => r.id == gr.rulon).nomi}
                </div>
              );
              obj.rulon_soni = gr.soni;
              obj.jami = gr.soni * 1 * metr * 1;
              child1.children.push(obj);
              break;
            case "y":
              child2.rulon_soni += gr.soni * 1;
              child2.jami += gr.soni * 1 * metr * 1;
              obj.key = gr.id;
              obj.nomi = (
                <div onClick={() => handleClick(gr)}>
                  {rulon.find(r => r.id == gr.rulon).nomi}
                </div>
              );
              obj.rulon_soni = gr.soni;
              obj.jami = gr.soni * 1 * metr * 1;
              child2.children.push(obj);
              break;
            case "r":
              child3.rulon_soni += gr.soni * 1;
              child3.jami += gr.soni * 1 * metr * 1;
              obj.key = gr.id;
              obj.nomi = (
                <div onClick={() => handleClick(gr)}>
                  {rulon.find(r => r.id == gr.rulon).nomi}
                </div>
              );
              obj.rulon_soni = gr.soni;
              obj.jami = gr.soni * 1 * metr * 1;
              child3.children.push(obj);
              break;

            default:
              break;
          }
        });

        if (child1.children.length > 0) {
          children.push(child1);
        }
        if (child2.children.length > 0) {
          children.push(child2);
        }
        if (child3.children.length > 0) {
          children.push(child3);
        }
        const obj = {
          key: id,
          nomi: maxsulotlar.find(m => m.id == id).nomi,
          rulon_soni: rulon_soni,
          jami: numberWithSpaces(jami_metr),
          children: children,
        };
        ostdata.push(obj);
      });
      if (location.pathname === "/qoldiq") {
        setData(ostdata.filter(ost => ost.key == rowId));
        setSearchDataSource(ostdata.filter(ost => ost.key == rowId));
      } else {
        setData(ostdata);
        setSearchDataSource(ostdata);
      }
    }
  }, [ostatka, rowId]);
  const handleClick = obj => {
    const b = {
      ...obj,
      id: uuid(),
      kirim_chiqim: 1,
    };
    setModalData(b);
    setOpenInfo(false);
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {location.pathname !== "/qoldiq" && (
          <SearchCom
            hide={true}
            columns={columns}
            setS={setSearchDataSource}
            costumData={data}
          ></SearchCom>
        )}
      </div>
      <NestedTable columns={columns} data={searchDataSource}></NestedTable>
    </>
  );
};
const mapStateToProps = state => {
  return {
    maxsulotlar: state.meros.allData.maxsulotlar,
    rulon: state.meros.allData.rulon,
    ostatka: state.meros.ostatka,
    rowId: state.meros.rowId,
  };
};
export default connect(mapStateToProps, {
  setOpenInfo,
  setModalData,
})(ChiqimInfo);
