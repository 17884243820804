import React, { useState } from "react";
import { RiAddCircleFill, RiIndeterminateCircleFill } from "react-icons/ri";
import { Button, InputNumber } from "antd";
import { connect } from "react-redux";
import uuid from "react-uuid";
import { setCardData } from "../../../Actions/MerosActions";
import { useEffect } from "react";

function numberFormat(x) {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  } else return x;
}
const MaxsulotForm = ({ clearModal, setCardData, cardData, values }) => {
  const [count, setCount] = useState(1);
  const [narx, setNarx] = useState(values.narxi);

  useEffect(() => {
    setNarx(values.narxi);
  }, [values]);
  const handleSaqlash = () => {
    const obj = {
      id: uuid(),
      nomi: values.nomi,
      narxi: narx,
      bonus: values.bonus,
      brend_nomi: values.brend_nomi,
      max_turi: values.max_turi,
      count: count,
    };
    const data = [...cardData, obj];

    setCardData(data);
    setCount(1);
    clearModal();
  };

  const onChange = v => {
    setCount(v);
  };

  const onSkidka = e => {
    if (e) {
      setNarx((values.narxi * (100 - e)) / 100);
    } else {
      setNarx(values.narxi);
    }
  };
  return (
    <>
      <h3
        style={{
          color: "var(--main-color)",
          fontWeight: "600",
          textAlign: "center",
        }}
      >
        {values.nomi}
      </h3>
      <div className="filial_body">
        <div>
          <span>Нархи:</span>
          <h5>
            {numberFormat(narx)} сўм{" "}
            {/* <InputNumber
              onChange={onSkidka}
              min="0"
              max="100"
              type="number"
              placeholder="skidka"
              keyboard={true}
            ></InputNumber> */}
          </h5>
        </div>
        <div>
          <span>Жами сумма ва сони:</span>
          <h2 style={{ color: "var(--main-color)", fontWeight: "bold" }}>
            {count} х {numberFormat(count * narx)} сўм
          </h2>
        </div>
      </div>
      <div className="filial_footer">
        <div className="filial_actions">
          <Button
            onClick={() => {
              if (count > 1) {
                setCount(count - 1);
              }
            }}
            type="primary"
          >
            <RiIndeterminateCircleFill style={{ fontSize: "20px" }} />
          </Button>
          <InputNumber
            min={1}
            style={{ textAlign: "center" }}
            value={count}
            onChange={onChange}
          />

          <Button onClick={() => setCount(count + 1)} type="primary">
            <RiAddCircleFill style={{ fontSize: "20px" }} />
          </Button>
        </div>
        <div>
          <Button
            onClick={handleSaqlash}
            style={{
              fontSize: "18px",
              fontWeight: "350",
              display: "flex",
              alignItems: "center",
              padding: "15px 35px",
            }}
            type="primary"
          >
            Сақлаш
          </Button>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = state => {
  return {
    values: state.meros.values,
    cardData: state.meros.cardData,
  };
};
export default connect(mapStateToProps, { setCardData })(MaxsulotForm);
