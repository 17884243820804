import React from "react";
import { connect } from "react-redux";
import { setOpenInfo } from "../../Actions/MerosActions";
import "./Modal.css";
import { Route, Switch, useHistory } from "react-router";
import Modal from "antd/lib/modal/Modal";
import { RiCloseCircleFill } from "react-icons/ri";
import KirimInfo from "./InfoForms/KirimInfo";
import ChiqimInfo from "./InfoForms/ChiqimInfo";
function ModalInfo({ setOpenInfo, openInfo }) {
  const { location } = useHistory();
  let text = "";
  switch (location.pathname) {
    case "/kirim":
      text = "Maxsulot qo'shish";
      break;
    case "/chiqim":
      text = "Tovar qo'shish";
      break;
    case "/bron":
      text = "Tovar qo'shish";
      break;

    case "/eslatma":
      text = "Maxsulot qo'shish";
    case "/qoldiq":
      text = "Maxsulot qoldig'i";
      break;

    default:
      break;
  }
  return (
    <Modal
      title={<div className="modal_title">{text}</div>}
      onCancel={() => setOpenInfo(false)}
      visible={openInfo}
      centered
      footer={null}
      closeIcon={
        <RiCloseCircleFill
          style={{
            fontSize: "25px",
            color: "#FF4B4B",
            verticalAlign: "middle",
          }}
        ></RiCloseCircleFill>
      }
      width="600px"
    >
      <div className="modal_content">
        <Switch>
          <Route exact path="/kirim">
            <KirimInfo></KirimInfo>
          </Route>
          <Route exact path="/eslatma">
            <KirimInfo></KirimInfo>
          </Route>
          <Route exact path="/chiqim">
            <ChiqimInfo></ChiqimInfo>
          </Route>
          <Route exact path="/qoldiq">
            <ChiqimInfo></ChiqimInfo>
          </Route>
          <Route exact path="/bron">
            <ChiqimInfo></ChiqimInfo>
          </Route>
        </Switch>
      </div>
    </Modal>
  );
}
const mapStateToProps = state => {
  return {
    openInfo: state.meros.openInfo,
  };
};
export default connect(mapStateToProps, {
  setOpenInfo,
})(ModalInfo);
