import React from "react";
import { Form } from "semantic-ui-react";

const BrendForm = ({ onChange, values, title }) => {
  return (
      <div className="brend_form">
          
      <div className="form_group">
        <Form.Field>
          <label>{title} номи</label>
          <input
            value={values.nomi ? values.nomi : ""}
            name="nomi"
            onChange={onChange}
          />
        </Form.Field>
              </div>
    </div>
  );
};

export default BrendForm;
