import React from "react";
import "./Filial.css";
import HisoblashishForm from "./FilialForms/HisoblashishForm";
import MaxsulotForm from "./FilialForms/MaxsulotForm";
import SotishForm from "./FilialForms/SotishForm";

const FilialForm = ({ act, clearModal }) => {
  return (
    <div className="filial_form">
      {act == "soni" ? (
        <MaxsulotForm clearModal={clearModal}></MaxsulotForm>
      ) : null}

      {act == "sotish" || act == "vozvrat" ? (
        <SotishForm act={act} clearModal={clearModal}></SotishForm>
      ) : null}
      {act == "hisoblashish" ? (
        <HisoblashishForm clearModal={clearModal}></HisoblashishForm>
      ) : null}
    </div>
  );
};

export default FilialForm;
