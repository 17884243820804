import React from "react";
import {
  RiBarcodeBoxFill,
  RiBuildingFill,
  RiFileChartFill,
  RiHammerFill,
  RiPencilRuler2Fill,
  RiPriceTag3Fill,
  RiShoppingCart2Fill,
  RiUserFill,
  RiVipCrownFill,
} from "react-icons/ri";
import { Switch } from "react-router";
import Brend from "../Components/Pages/Brend";
import Maxsulotlar from "../Components/Pages/Maxsulotlar";
import Sotuv from "../Components/Pages/Sotuv";
import Users from "../Components/Pages/Users";
import Ustalar from "../Components/Pages/Ustalar";
import Xisobot from "../Components/Pages/Xisobot";
import Sidebar from "./Sidebar";

const routes = [
  {
    title: "Бренд",
    path: "/",
    icon: <RiVipCrownFill></RiVipCrownFill>,
    comp: <Brend path="/" nomi="Бренд"></Brend>,
  },
  {
    title: "Махсулот тури",
    path: "/maxsulotturi",
    icon: <RiPriceTag3Fill></RiPriceTag3Fill>,
    comp: <Brend path="/maxsulotturi" nomi="Махсулот"></Brend>,
  },
  {
    title: "Ўлчов бирлиги",
    path: "/olchov",
    icon: <RiPencilRuler2Fill></RiPencilRuler2Fill>,
    comp: <Brend path="/olchov" nomi="Ўлчов"></Brend>,
  },
  {
    title: "Махсулотлар",
    path: "/maxsulotlar",
    icon: <RiBarcodeBoxFill></RiBarcodeBoxFill>,
    comp: <Maxsulotlar path="/maxsulotlar"></Maxsulotlar>,
  },
  {
    title: "Усталар",
    path: "/ustalar",
    icon: <RiHammerFill></RiHammerFill>,
    comp: <Ustalar path="/ustalar"></Ustalar>,
  },
  {
    title: "Филиаллар",
    path: "/filiallar",
    icon: <RiBuildingFill></RiBuildingFill>,
    comp: <Brend path="/filiallar"></Brend>,
  },
  {
    title: "Сотув",
    path: "/sotuv",
    icon: <RiShoppingCart2Fill></RiShoppingCart2Fill>,
    comp: <Sotuv path="/sotuv"></Sotuv>,
  },
  {
    title: "Хисобот",
    path: "/hisobot",
    icon: <RiFileChartFill></RiFileChartFill>,
    comp: <Xisobot path="/bonuslar"></Xisobot>,
  },
  {
    title: "Фойдаланувчилар",
    path: "/foydalanuvchi",
    icon: <RiUserFill></RiUserFill>,
    comp: <Users path="/foydalanuvchi"></Users>,
  },
];
const Meros = () => {
  return (
    <>
      <Switch>
        <Sidebar routes={routes}></Sidebar>
      </Switch>
    </>
  );
};

export default Meros;
