
import React, { useEffect, useState } from "react";


import { connect } from "react-redux";
import { openSide } from "../Actions/MerosActions";
import Actions from "./Actions";
import ModalExampleModal from "./Modal/Modal";

import MainTable from "./Table/MainTable";

import Header from "./Header";
const Body = ({ columns, data, rowS, openSide, act }) => {
  const [searchDataSource, setSearchDataSource] = useState([]);
  useEffect(() => {
    if (rowS) {
      setSearchDataSource(
        data.map(d => {
          return { ...d, key: d.id };
        })
      );
    } else {
      setSearchDataSource(data);
    }
  }, [data]);
 
  
  return (
    <>
      <Header columns={columns} setSearchDataSource={setSearchDataSource}></Header>
      <div className="Body" onClick={() => openSide(false)}>
       <Actions act={act}></Actions>
        <MainTable
          columns={columns}
          data={searchDataSource}
          rowS={rowS}
        ></MainTable>
        <ModalExampleModal></ModalExampleModal>
      </div>
    </>
  );
};
const mapStateToProps = state => {
  return {
    data: state.meros.mainTableData,
  };
};
export default connect(mapStateToProps, { openSide })(Body);
