import React, { useEffect, useState } from "react";
import Meros from "./Layout/Meros";
import "semantic-ui-css/semantic.min.css";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import Login from "./Layout/Login";
import { connect } from "react-redux";
import FilialSavdo from "./Components/Pages/FilialSavdo";

function App({auth}) {
  const [user, setUser] = useState();
  useEffect(() => {
      setUser(window.localStorage.getItem("user"));
  }, [auth])
  let main = null

  if (user == 0) {
    main = <Meros></Meros>;
  } else if (user && user !=0) {
    main = <FilialSavdo></FilialSavdo>
  }
  else {
    main = <Login></Login>;
  }
  return (
    <>
      <Router>
       {main}
      </Router>
    </>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.meros.auth
  }
}
export default connect(mapStateToProps)(App);
