import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getAllData, getMainTableData } from "../../Actions/MerosActions";
import Body from "../Body";



const Users = ({ getMainTableData, path,getAllData, allData }) => {
  const columns = [
    {
      title: <div className="table_head">№</div>,
      key: "index",
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">Фамилия исми</div>,
      dataIndex: "fio",
      key: "nomi",
      },
      {
        title: <div className="table_head">Филиал</div>,
        dataIndex: "filial_id",
          key: "nomi",
          render: (text) => {
            if (allData.filial && allData.filial.find(d=> d.id == text)) {
                return allData.filial.find(d=> d.id == text).nomi
            }else return null
        }
      },
      {
        title: <div className="table_head">Логин</div>,
        dataIndex: "login",
        key: "nomi",
      },
      {
        title: <div className="table_head">Парол</div>,
        dataIndex: "parol",
        key: "nomi",
      }
  ];
    useEffect(() => {
    getAllData("/secdata")
    getMainTableData(path);
  }, []);
  return <Body columns={columns}></Body>;
};
const mapStateToProps = state => {
    return {
        allData: state.meros.allData
    }
}
export default connect(mapStateToProps, { getMainTableData,getAllData })(Users);
